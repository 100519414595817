import React, {
  CSSProperties,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import * as R from 'ramda';

import WorkTemplateTree from '@atom/components/common/workTemplateTree/WorkTemplateTree';
import { Button, Checkbox, List, Modal } from '@atom/mui';
import { WorkTemplate } from '@atom/types/workTemplate';

import './../assetWorkOrdersFilterPanel.css';

const { ListItem, ListItemText } = List;

const styles: Record<any, CSSProperties> = {
  button: {
    minWidth: 0,
    padding: 0,
  },
  modal: {
    height: '50vh',
    padding: '2rem',
  },
  checkbox: {
    padding: 0,
    marginRight: '1rem',
  },
};

interface Props {
  schemaId: string;
  loadingWorkOrders: boolean;
  hasWorkTemplateFilter?: boolean;
  setHasWorkTemplateFilter: Dispatch<SetStateAction<boolean | null>>;
  workTemplateFilter: WorkTemplate[];
  setWorkTemplateFilter: Dispatch<WorkTemplate[]>;
}

const TemplateFilter = ({
  schemaId,
  loadingWorkOrders,
  hasWorkTemplateFilter,
  setHasWorkTemplateFilter,
  workTemplateFilter,
  setWorkTemplateFilter,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [hasWorkTemplate, setHasWorkTemplate] = useState<boolean | null>(
    hasWorkTemplateFilter,
  );
  const [selectedTemplates, setSelectedTemplates] = useState<WorkTemplate[]>(
    [],
  );

  const selectedTemplateIds: Set<string> = new Set(
    selectedTemplates.map(template => template.id),
  );

  const handleHasWorkTemplate = (event, isAll: boolean) => {
    setSelectedTemplates([]);
    setHasWorkTemplate(event.target.checked ? isAll : null);
  };

  const handleToggleWorkTemplate = toggleTemplate => {
    const isSelected = selectedTemplateIds.has(toggleTemplate.id);
    let updatedSelected: WorkTemplate[];
    if (isSelected) {
      updatedSelected = selectedTemplates.filter(
        template => template.id !== toggleTemplate.id,
      );
    } else {
      updatedSelected = [...selectedTemplates, toggleTemplate];
    }
    setSelectedTemplates(updatedSelected);
  };

  const alignLocalToParentState = () => {
    setHasWorkTemplate(hasWorkTemplateFilter);
    setSelectedTemplates(workTemplateFilter);
  };

  const onOpenModal = () => {
    setIsModalOpen(true);
    alignLocalToParentState();
  };

  const onCancelModal = () => {
    alignLocalToParentState();
    setIsModalOpen(false);
  };

  const onConfirmModal = () => {
    setHasWorkTemplateFilter(hasWorkTemplate);
    setWorkTemplateFilter(selectedTemplates);
    setIsModalOpen(false);
  };

  const onClearModal = () => {
    setHasWorkTemplate(null);
    setSelectedTemplates([]);
  };

  const onClearFilter = () => {
    setHasWorkTemplateFilter(null);
    setWorkTemplateFilter([]);
  };

  const showHasWorkTemplateText: boolean = !R.isNil(hasWorkTemplateFilter);
  const showClearButton: boolean =
    !R.isNil(hasWorkTemplateFilter) || !R.isEmpty(workTemplateFilter);

  return (
    <>
      <div styleName="filter-container">
        <div styleName="filter-label">Work Template</div>
        <div>
          {showHasWorkTemplateText &&
            (hasWorkTemplateFilter
              ? 'All Work Templates'
              : 'Without Work Template')}
          {workTemplateFilter.map(template => template.name).join(', ')}
        </div>
        <div styleName="filter-button-pair">
          <Button
            size="small"
            onClick={onOpenModal}
            style={styles.button}
            disabled={loadingWorkOrders}
          >
            select
          </Button>
          {showClearButton && (
            <Button
              size="small"
              onClick={onClearFilter}
              style={styles.button}
              disabled={loadingWorkOrders}
            >
              clear
            </Button>
          )}
        </div>
      </div>
      <Modal
        open={isModalOpen}
        title={'Select Work Template'}
        onCancel={onCancelModal}
        onConfirm={onConfirmModal}
        onClear={onClearModal}
        width="lg"
        contentStyle={styles.modal}
      >
        <WorkTemplateTree
          includeSearch
          schemaId={schemaId}
          hasSchema={false}
          getTemplateDisabled={() => !R.isNil(hasWorkTemplate)}
          getTemplateSelected={template =>
            R.equals(hasWorkTemplate, true) ||
            selectedTemplateIds.has(template.id)
          }
          onTemplateToggle={handleToggleWorkTemplate}
          additionalContentTop={
            <>
              <ListItem disableGutters>
                <Checkbox
                  style={styles.checkbox}
                  checked={R.equals(hasWorkTemplate, false)}
                  onChange={event => handleHasWorkTemplate(event, false)}
                />
                <ListItemText>Without Work Template</ListItemText>
              </ListItem>
              <ListItem disableGutters>
                <Checkbox
                  style={styles.checkbox}
                  checked={R.equals(hasWorkTemplate, true)}
                  onChange={event => handleHasWorkTemplate(event, true)}
                />
                <ListItemText>All Work Templates</ListItemText>
              </ListItem>
            </>
          }
        />
      </Modal>
    </>
  );
};

export default TemplateFilter;
