import { UserDetail } from './user';

export enum PolicySubjectType {
  USER = 'USER',
  USER_GROUP = 'USER_GROUP',
  ROLE = 'ROLE',
}

export enum PolicyAction {
  ALL = 'ALL',
  READ = 'READ',
  CREATE = 'CREATE',
  BULK_CREATE = 'BULK_CREATE',
  UPDATE = 'UPDATE',
  BULK_UPDATE = 'BULK_UPDATE',
  DELETE = 'DELETE',
  RENAME = 'RENAME',
  UPDATE_LOCATION = 'UPDATE_LOCATION',
  UPDATE_RELATIONSHIP = 'UPDATE_RELATIONSHIP',
  MANAGE_INVENTORY_CHANGES = 'MANAGE_INVENTORY_CHANGES',
  CREATE_MEDIA = 'CREATE_MEDIA',
  UPDATE_MEDIA = 'UPDATE_MEDIA',
  DELETE_MEDIA = 'DELETE_MEDIA',
  MANAGE_COLUMN_DISPLAY = 'MANAGE_COLUMN_DISPLAY',
  REOPEN_CLOSED_WORK = 'REOPEN_CLOSED_WORK',
}

export enum PolicyResource {
  INVENTORY_TYPE = 'INVENTORY_TYPE',
  INVENTORY_FOLDER = 'INVENTORY_FOLDER',
  INVENTORY_ITEM = 'INVENTORY_ITEM',
  WORK_TYPE_FOLDER = 'WORK_TYPE_FOLDER',
  ANALYTICS_FOLDER = 'ANALYTICS_FOLDER',
  WORK_LIST = 'WORK_LIST',
  BUDGET = 'BUDGET', // for User Budget Policy
  BUDGETING = 'BUDGETING',
  BUDGETING_BUDGET = 'BUDGETING_BUDGET',
  BUDGETING_TEMPLATE = 'BUDGETING_TEMPLATE',
  BUDGETING_UNIT = 'BUDGETING_UNIT',
  WORK = 'WORK',
}

export enum GrantType {
  INVENTORY_TYPE = 'INVENTORY_TYPE',
  INVENTORY_FOLDER = 'INVENTORY_FOLDER',
  INVENTORY_ITEM = 'INVENTORY_ITEM',
  WORK_TYPE_FOLDER = 'WORK_TYPE_FOLDER',
  ANALYTICS_FOLDER = 'ANALYTICS_FOLDER',
  WORK_LIST = 'WORK_LIST',
  USER = 'USER',
  BUDGETING_BUDGET = 'BUDGETING_BUDGET',
  BUDGETING_TEMPLATE = 'BUDGETING_TEMPLATE',
  BUDGETING_UNIT = 'BUDGETING_UNIT',
}

export interface Resource {
  id: string;
  name: string;
}

export interface PolicyGrant {
  type: GrantType;
  ids: string;
}

export interface Policy {
  id: string;
  subjectType: PolicySubjectType;
  subjectId: string;
  action: PolicyAction;
  resource: PolicyResource;
  grant: PolicyGrant;
  grants: Resource[];
  createdBy: UserDetail;
  createdDate: number;
  updatedBy?: UserDetail;
  updatedDate?: number;
}

export interface PoliciesConnection {
  totalCount: number;
  policies: Policy[];
}

export interface PoliciesConnectionInput {
  page?: number;
  limit?: number;
  subjectIds?: string[];
  resources?: string[];
  sortBy?: 'resource' | 'createdDate';
  sortDirection?: 'asc' | 'desc';
}

export interface PolicyCreateInput {
  subjectType: PolicySubjectType;
  subjectId: string;
  action: PolicyAction;
  resource: PolicyResource;
  grant: PolicyGrant;
}

export interface PolicyUpdateInput {
  id: string;
  action?: PolicyAction;
  resource?: PolicyResource;
  grant?: PolicyGrant;
}

export const POLICY_GRANT_IDS_ALL = 'ALL';
