import React, { Dispatch, SetStateAction, useState } from 'react';
import * as R from 'ramda';

import InputDateRange from '@atom/components/common/inputDateRange/InputDateRange';
import { Button, Select } from '@atom/mui';
import { EventType } from '@atom/types/event';
import { FieldFilter, WorkOrdersConnectionInput } from '@atom/types/work';
import {
  WorkOrderTemplateFolder,
  WorkTemplate,
} from '@atom/types/workTemplate';
import {
  convertDateToLocalDayEnd,
  convertDateToLocalDayStart,
} from '@atom/utilities/timeUtilities';
import {
  DateRangePresetOption,
  DateRangeTerminator,
} from '@atom/utilities/workOrdersDateFilterUtilities';
import { workOrderStatusTypes } from '@atom/utilities/workOrderStatusUtilities';

import TemplateFilter from './AssetWorkOrdersFilters/TemplateFilter';
import TemplateFolderFilter from './AssetWorkOrdersFilters/TemplateFolderFilter';
import WorkTemplateFieldsFilter from './AssetWorkOrdersFilters/WorkTemplateFieldsFilter';

import './assetWorkOrdersFilterPanel.css';

const { MenuItem: SelectMenuItem } = Select;

interface FilterDefault {
  statusFilter: Set<number>;
  dueDateStartFilter?: number;
  dueDateEndFilter?: number;
  completedDateStartFilter?: number;
  completedDateEndFilter?: number;
  hasWorkTemplate?: boolean;
  dueDateSelectValue?: string;
  completionDateSelectValue?: string;
  workTemplateFolders: WorkOrderTemplateFolder[];
  workTemplates: WorkTemplate[];
  workTemplateFields: Record<string, FieldFilter>;
}

const FILTER_DEFAULTS: FilterDefault = {
  statusFilter: new Set([]),
  dueDateStartFilter: null,
  dueDateEndFilter: null,
  completedDateStartFilter: null,
  completedDateEndFilter: null,
  hasWorkTemplate: null,
  dueDateSelectValue: null,
  completionDateSelectValue: null,
  workTemplateFolders: [],
  workTemplates: [],
  workTemplateFields: {},
};

interface Props {
  schemaId: string;
  loadingWorkOrders: boolean;
  isResetFilterButtonDisabled: boolean;
  setActiveFilters: Dispatch<SetStateAction<WorkOrdersConnectionInput>>;
  setCurrentPage: Dispatch<SetStateAction<number>>;
}

const AssetWorkOrdersFilterPanel = ({
  schemaId,
  loadingWorkOrders,
  isResetFilterButtonDisabled,
  setActiveFilters,
  setCurrentPage,
}: Props) => {
  const [selectStatuses, setSelectStatuses] = useState<Set<number>>(
    FILTER_DEFAULTS.statusFilter,
  );
  const [dueDateStart, setDueDateStart] = useState<number | null>(
    FILTER_DEFAULTS.dueDateStartFilter,
  );
  const [dueDateEnd, setDueDateEnd] = useState<number | null>(
    FILTER_DEFAULTS.dueDateEndFilter,
  );
  const [completionDateStart, setCompletionDateStart] = useState<number | null>(
    FILTER_DEFAULTS.completedDateStartFilter,
  );
  const [completionDateEnd, setCompletionDateEnd] = useState<number | null>(
    FILTER_DEFAULTS.completedDateEndFilter,
  );
  const [hasWorkTemplate, setHasWorkTemplate] = useState<boolean | null>(
    FILTER_DEFAULTS.hasWorkTemplate,
  );
  const [dueDateSelectValue, setDueDateSelectValue] = useState<string>(
    FILTER_DEFAULTS.dueDateSelectValue,
  );
  const [completionDateSelectValue, setCompletionDateSelectValue] = useState<
    string
  >(FILTER_DEFAULTS.completionDateSelectValue);
  const [workTemplateFolders, setWorkTemplateFolders] = useState<
    WorkOrderTemplateFolder[]
  >(FILTER_DEFAULTS.workTemplateFolders);
  const [workTemplates, setWorkTemplates] = useState<WorkTemplate[]>(
    FILTER_DEFAULTS.workTemplates,
  );
  const [workTemplateFields, setWorkTemplateFields] = useState<
    Record<any, FieldFilter>
  >(FILTER_DEFAULTS.workTemplateFields);

  const onApplyFilters = () => {
    setActiveFilters({
      ...(selectStatuses && { statusIds: [...selectStatuses] }),
      ...(dueDateStart && { dueDateStart }),
      ...(dueDateEnd && { dueDateEnd }),
      ...(completionDateStart && { completionDateStart }),
      ...(completionDateEnd && { completionDateEnd }),
      ...(!R.isNil(hasWorkTemplate) && { hasWorkTemplate }),
      ...(!R.isEmpty(workTemplates) && {
        workTemplateIds: [...workTemplates.map(template => template.id)],
      }),
      ...(!R.isEmpty(workTemplateFolders) && {
        workTemplateFolderIds: [
          ...workTemplateFolders.map(folder => folder.id),
        ],
      }),
      ...(!R.isEmpty(workTemplateFields) && {
        fields: Object.values(workTemplateFields),
      }),
    });
    setCurrentPage(1);
  };

  const onResetFilters = () => {
    setSelectStatuses(FILTER_DEFAULTS.statusFilter);
    setDueDateStart(FILTER_DEFAULTS.dueDateStartFilter);
    setDueDateEnd(FILTER_DEFAULTS.dueDateEndFilter);
    setCompletionDateStart(FILTER_DEFAULTS.completedDateStartFilter);
    setCompletionDateEnd(FILTER_DEFAULTS.completedDateEndFilter);
    setHasWorkTemplate(FILTER_DEFAULTS.hasWorkTemplate);
    setDueDateSelectValue(FILTER_DEFAULTS.dueDateSelectValue);
    setCompletionDateSelectValue(FILTER_DEFAULTS.completionDateSelectValue);
    setWorkTemplateFolders(FILTER_DEFAULTS.workTemplateFolders);
    setWorkTemplates(FILTER_DEFAULTS.workTemplates);
    setWorkTemplateFields(FILTER_DEFAULTS.workTemplateFields);
    setActiveFilters({});
    setCurrentPage(1);
  };

  const handleStatusSelect = (event: EventType) => {
    const {
      target: { value },
    } = event;
    setSelectStatuses(value);
  };

  const handleDateChange = (
    dateValue: Date,
    terminator: DateRangeTerminator,
    setState: Dispatch<SetStateAction<number | null>>,
  ) => {
    const dateMillis = dateValue
      ? terminator === DateRangeTerminator.START
        ? convertDateToLocalDayStart(dateValue)
        : convertDateToLocalDayEnd(dateValue)
      : null;
    setState(dateMillis);
  };

  const handleDateSelectChange = (
    setStateFunction,
    option: DateRangePresetOption,
  ) => {
    setStateFunction(option.value);
  };

  return (
    <>
      <div styleName="header filter-section">
        <div styleName="header-title">Filters</div>
        <div styleName="header-button-container">
          <Button
            onClick={onResetFilters}
            disabled={isResetFilterButtonDisabled || loadingWorkOrders}
          >
            Reset
          </Button>
          <Button
            color="primary"
            onClick={onApplyFilters}
            disabled={loadingWorkOrders}
          >
            Apply
          </Button>
        </div>
      </div>
      <div styleName="filter-section">
        <div styleName="filter-container">
          <InputDateRange
            label="Due Date"
            disabled={loadingWorkOrders}
            presetSelected={dueDateSelectValue}
            handleSelectDatePreset={(option: DateRangePresetOption) =>
              handleDateSelectChange(setDueDateSelectValue, option)
            }
            startDateValue={dueDateStart ? new Date(dueDateStart) : null}
            handleStartChange={(dateValue: Date) =>
              handleDateChange(
                dateValue,
                DateRangeTerminator.START,
                setDueDateStart,
              )
            }
            endDateValue={dueDateEnd ? new Date(dueDateEnd) : null}
            handleEndChange={(dateValue: Date) =>
              handleDateChange(
                dateValue,
                DateRangeTerminator.END,
                setDueDateEnd,
              )
            }
          />
        </div>
        <div styleName="filter-container">
          <InputDateRange
            label="Completed Date"
            disabled={loadingWorkOrders}
            presetSelected={completionDateSelectValue}
            handleSelectDatePreset={(option: DateRangePresetOption) =>
              handleDateSelectChange(setCompletionDateSelectValue, option)
            }
            startDateValue={
              completionDateStart ? new Date(completionDateStart) : null
            }
            handleStartChange={(dateValue: Date) =>
              handleDateChange(
                dateValue,
                DateRangeTerminator.START,
                setCompletionDateStart,
              )
            }
            endDateValue={
              completionDateEnd ? new Date(completionDateEnd) : null
            }
            handleEndChange={(dateValue: Date) =>
              handleDateChange(
                dateValue,
                DateRangeTerminator.END,
                setCompletionDateEnd,
              )
            }
          />
        </div>
        <div styleName="filter-container">
          <div styleName="filter-label">Work Status</div>
          <Select
            multiple
            displayEmpty
            value={[...selectStatuses]}
            onChange={event => handleStatusSelect(event)}
            disabled={loadingWorkOrders}
          >
            {workOrderStatusTypes.map(status => (
              <SelectMenuItem key={+status.value} value={+status.value}>
                {status.label}
              </SelectMenuItem>
            ))}
          </Select>
        </div>
        <TemplateFolderFilter
          schemaId={schemaId}
          loadingWorkOrders={loadingWorkOrders}
          workTemplateFolderFilter={workTemplateFolders}
          setWorkTemplateFolderFilter={setWorkTemplateFolders}
        />
        <TemplateFilter
          schemaId={schemaId}
          loadingWorkOrders={loadingWorkOrders}
          hasWorkTemplateFilter={hasWorkTemplate}
          setHasWorkTemplateFilter={setHasWorkTemplate}
          workTemplateFilter={workTemplates}
          setWorkTemplateFilter={setWorkTemplates}
        />
        {R.length(workTemplates) === 1 && (
          <WorkTemplateFieldsFilter
            loadingWorkOrders={loadingWorkOrders}
            workTemplate={workTemplates[0]}
            fieldFilters={workTemplateFields}
            setFieldFilters={setWorkTemplateFields}
          />
        )}
      </div>
    </>
  );
};

export default AssetWorkOrdersFilterPanel;
