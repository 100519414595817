// This Component displays the row and manages the compare popup state

import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { ClickAwayListener } from '@mui/material';
import * as R from 'ramda';

import PercentBar from '@atom/components/common/percentBar/PercentBar';
import { usePreferences } from '@atom/hooks/usePreferences';
import { Icon, ListTable, Tooltip } from '@atom/mui';
import colors from '@atom/styles/colors';
import { BudgetCategory, BudgetItem } from '@atom/types/budget';
import { numberToLocaleString } from '@atom/utilities/currencyUtility';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

import BudgetDetailComparison from '../BudgetDetailComparison';
import BudgetDetailContext from '../BudgetDetailContext';
import {
  BUDGET_COL_WIDTH,
  COMMENT_COL_WIDTH,
  COST_TYPE_COL_WIDTH,
  EditField,
  FIXED_COST_TOOLTIP_COPY,
  getComparisonIcon,
  ICON_COL_WIDTH,
} from '../budgetDetailUtils';

import '../budgetDetail.css';

const BLANK_CHAR = '\u00A0';

const { TableRow, TableCell } = ListTable;

interface Props {
  budgetItem: BudgetItem;
  budgetCategory: BudgetCategory;
  handleInputCellClick: (field: EditField, budgetItem: BudgetItem) => void;
  loadingItemUpdate: boolean;
  handleInputValueChange: (event, prevTotal) => void;
  handleKeyUp: (event) => void;
  handleSaveField: () => void;
  editValue: string;
  setEditValue: Dispatch<SetStateAction<string>>;
}

const styles = {
  costTypeCell: {
    paddingLeft: '0',
  },
  nameCell: {
    paddingLeft: '2rem',
  },
  itemCell: {
    padding: 0,
  },
  infoCell: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  },
  totalBudgetLabelContainer: {
    height: '0.75rem',
  },
  fixedInfoIcon: { fontSize: '0.75rem' },
  valueCellEditing: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.15rem',
  },
  input: {
    width: '95%',
    height: '100%',
    border: `1px solid ${colors.utility.activeBlue}`,
    borderRadius: '0.25rem',
    padding: '0.5rem 0.15rem',
  },
  budgetCommentCell: {
    padding: 0,
    width: '95%',
  },
};

const BudgetDetailItemRow = ({
  budgetItem,
  budgetCategory,
  handleInputCellClick,
  loadingItemUpdate,
  handleInputValueChange,
  handleKeyUp,
  handleSaveField,
  editValue,
  setEditValue,
}: Props) => {
  const {
    budgetSummaries,
    comparisonBudgets,
    openComparisonRowId,
    setOpenComparisonRowId,
    parentBudgetUnit,
    editingField,
    editingItem,
    setEditingItem,
    fetchSummaries,
    loadingSummaries,
    itemHoverId,
    setItemHoverId,
    showTracking,
  } = useContext(BudgetDetailContext);

  const preferences = usePreferences();
  const hideCostType = R.pathOr(
    false,
    ['budgeting', 'hideCostType'],
    preferences,
  );

  const open: boolean = useMemo(() => openComparisonRowId === budgetItem?.id, [
    openComparisonRowId,
  ]);

  const hovering: boolean = useMemo(() => itemHoverId === budgetItem?.id, [
    itemHoverId,
  ]);

  const anchorRef = useRef();

  const handleFocus = event => event.target.select();

  useEffect(() => {
    if (open && !isNilOrEmpty(comparisonBudgets)) {
      fetchSummaries({
        variables: {
          input: {
            budgetUnitId: parentBudgetUnit?.id,
            budgetIds: R.pluck('id', comparisonBudgets),
            categoryIds: [budgetCategory?.id],
            itemNames: [budgetItem.name],
          },
        },
      });
    }
  }, [open, comparisonBudgets]);

  const handleCompareClick = (event: MouseEvent) => {
    event.stopPropagation();
    setOpenComparisonRowId(open ? null : budgetItem?.id);
  };

  const handleOutsideClickCancelEdit = event => {
    event.stopPropagation();
    setEditingItem(null);
  };

  const handleOutsideClickCloseComparison = event => {
    event.stopPropagation();
    // Close if user is not hovering over the current comparison row
    // - this allows editing the row while leaving the popup open
    if (isNilOrEmpty(itemHoverId) || itemHoverId !== openComparisonRowId) {
      setOpenComparisonRowId(null);
    }
  };

  return (
    <>
      <TableRow
        onMouseEnter={() => setItemHoverId(budgetItem?.id)}
        onMouseLeave={() => setItemHoverId(null)}
        hovercolor={colors.utility.lightBlueHighlight}
        style={{
          backgroundColor: open
            ? colors.utility.lightBlueHighlight
            : colors.neutral.white,
        }}
      >
        <TableCell style={{ width: '100%' }}>
          <span styleName="budget-table-cell" style={styles.nameCell}>
            {budgetItem?.name}
          </span>
        </TableCell>
        {!hideCostType && (
          <TableCell width={COST_TYPE_COL_WIDTH} style={styles.costTypeCell}>
            <span styleName="budget-table-cell" style={styles.itemCell}>
              <span>{budgetItem.costType}</span>
              {hovering && budgetItem.costType === 'Fixed Cost' && (
                <Tooltip title={`Fixed Cost: ${FIXED_COST_TOOLTIP_COPY}`}>
                  <span style={styles.totalBudgetLabelContainer}>
                    <Icon style={styles.fixedInfoIcon}>info_outlined</Icon>
                  </span>
                </Tooltip>
              )}
            </span>
          </TableCell>
        )}

        <TableCell
          onClick={() => handleInputCellClick(EditField.VALUE, budgetItem)}
          styleName="clickable"
          align="right"
          width={BUDGET_COL_WIDTH}
        >
          <span styleName="budget-table-cell" style={styles.itemCell}>
            {!loadingItemUpdate &&
            editingField === EditField.VALUE &&
            editingItem?.id === budgetItem?.id ? (
              <ClickAwayListener onClickAway={handleOutsideClickCancelEdit}>
                <span style={styles.valueCellEditing}>
                  $
                  <input
                    style={{ ...styles.input, ...{ textAlign: 'right' } }}
                    onChange={event =>
                      handleInputValueChange(event, budgetItem?.budgetValue)
                    }
                    onKeyUp={event => handleKeyUp(event)}
                    onBlur={handleSaveField}
                    value={editValue}
                    autoFocus
                    onFocus={handleFocus}
                  />
                </span>
              </ClickAwayListener>
            ) : (
              <span styleName="edit-hover">
                {numberToLocaleString(budgetItem?.budgetValue)}
              </span>
            )}
          </span>
        </TableCell>
        {showTracking && (
          <>
            <TableCell align="right" width={BUDGET_COL_WIDTH}>
              <span styleName="budget-table-cell">
                {numberToLocaleString(budgetItem?.actualBudget)}
              </span>
            </TableCell>
            <TableCell align="right" width={BUDGET_COL_WIDTH}>
              <PercentBar
                value={budgetItem?.remaining}
                limit={budgetItem?.budgetValue}
              />
            </TableCell>
          </>
        )}
        <TableCell
          onClick={() => handleInputCellClick(EditField.COMMENT, budgetItem)}
          styleName="clickable"
          width={COMMENT_COL_WIDTH}
        >
          <span styleName="budget-table-cell" style={styles.budgetCommentCell}>
            {!loadingItemUpdate &&
            editingField === EditField.COMMENT &&
            editingItem?.id === budgetItem?.id ? (
              <ClickAwayListener onClickAway={handleOutsideClickCancelEdit}>
                <input
                  style={styles.input}
                  value={editValue}
                  onChange={event => setEditValue(event.target.value)}
                  onKeyUp={event => handleKeyUp(event)}
                  onBlur={handleSaveField}
                  onFocus={handleFocus}
                  autoFocus
                />
              </ClickAwayListener>
            ) : (
              <Tooltip title={budgetItem?.comment}>
                <span styleName="text-no-wrap edit-hover">
                  {budgetItem?.comment || BLANK_CHAR}
                </span>
              </Tooltip>
            )}
          </span>
        </TableCell>
        <TableCell width={ICON_COL_WIDTH}>
          <span
            ref={anchorRef}
            onClick={handleCompareClick}
            styleName="budget-table-cell clickable"
          >
            {getComparisonIcon(itemHoverId === budgetItem?.id, open)}
          </span>
        </TableCell>
      </TableRow>
      {open && (
        <BudgetDetailComparison
          handleCloseModal={() => setOpenComparisonRowId(null)}
          loadingSummaries={loadingSummaries}
          budgetSummaries={budgetSummaries}
          includeComments
          anchorEl={anchorRef?.current}
          onClickAway={handleOutsideClickCloseComparison}
          showFixedCosts={false}
        />
      )}
    </>
  );
};

export default BudgetDetailItemRow;
