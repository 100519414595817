export enum BudgetModal {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  DUPLICATE = 'DUPLICATE',
}

export interface Budget {
  id: string;
  name: string;
  templateId: string;
  templateName: string;
  startDate: Date;
  endDate: Date;
  totalBudget: number;
  actualBudget: number;
  fixedCosts: number;
  remaining: number;
  rootBudgetUnitId: string;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
  createdDate: number;
}

export interface BudgetsConnection {
  totalCount: number;
  budgets: Budget[];
}

export interface BudgetConnection {
  budget: Budget;
}

export interface BudgetsConnectionInput {
  page?: number;
  limit?: number;
  sortBy?: string;
  templateId?: string;
}

export interface BudgetSummary {
  id: string;
  name: string;
  budgetValue?: number;
  actualBudget: number;
  fixedCosts: number;
  remaining: number;
  comment?: string;
  startDate: number;
  endDate: number;
}

export interface BudgetSummaryConnectionInput {
  budgetUnitId: string;
  budgetIds: string[];
  categoryIds?: string[];
  itemNames?: string[];
}

export interface BudgetSummaryConnection {
  totalCount: number;
  budgetSummary: BudgetSummary[];
}

export interface BasicBudget {
  id: string;
  name: string;
  startDate: number;
  endDate: number;
}

export interface BudgetOptionsConnection {
  totalCount: number;
  budgets: BasicBudget[];
}

export interface BudgetSearchInput {
  query: string;
  budgetTemplateId: string;
  page: number;
  limit: number;
}

export interface BudgetSearchConnection {
  totalCount: number;
  budgets: BasicBudget[];
}

export interface BudgetCreateInput {
  budgetTemplateId: string;
  name: string;
  startDate: number;
  endDate: number;
}

export interface BudgetUpdateInput {
  id: string;
  name: string;
  startDate: number;
  endDate: number;
}

export interface BudgetDuplicateInput {
  budgetId: string;
  budgetTemplateId: string;
  name: string;
  startDate: number;
  endDate: number;
}

export interface BudgetTemplate {
  id: string;
  name: string;
}

export interface BudgetTemplatesConnection {
  totalCount: number;
  budgetTemplates: BudgetTemplate[];
}

export interface BudgetItem {
  id: string;
  name: string;
  budgetValue: number;
  actualBudget: number;
  fixedCosts?: number;
  remaining: number;
  budgetItemTemplateId: string;
  budgetTemplateId: string;
  budgetCategoryId: string;
  budgetId: string;
  budgetUnitsPath: string[];
  comment: string;
  costType: string;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
  createdDate: number;
}

export interface BudgetItemUpdateInput {
  budgetId: string;
  budgetItemId: string;
  budgetValue?: number;
  comment?: string;
}

export interface BudgetCategory {
  id: string;
  name: string;
  totalBudget?: number;
  budgetItems?: BudgetItem[];
  actualBudget: number;
  fixedCosts?: number;
  remaining: number;
}

export interface BudgetCategoriesConnection {
  totalCount: number;
  budgetCategories: BudgetCategory[];
}

export interface BudgetCategoriesConnectionInput {
  budgetId: string;
  budgetUnitId: string;
  categoryIds: string[];
  budgetItemTemplateNames: string[];
  excludeZeroBudgetItems?: boolean;
}

export interface BudgetCategoryFiltersConnection {
  totalCount: number;
  budgetCategories: BudgetCategory[];
}

export interface BudgetCategoriesFilterConnectionInput {
  budgetId: string;
  name?: string;
  page?: number;
  limit?: number;
  isAscending?: boolean;
}

export interface BudgetItemTemplate {
  id: string;
  name: string;
}

export interface BudgetItemTemplatesFilterConnection {
  totalCount: number;
  budgetItemTemplates: BudgetItemTemplate[];
}

export interface BudgetItemTemplatesFilterConnectionInput {
  budgetId: string;
  name?: string;
  page?: number;
  limit?: number;
  sortBy?: string;
  isAscending?: boolean;
}

export interface BudgetUnitTree {
  id: string;
  name: string;
  unitPath: [string];
  children: BudgetUnitTree[];
  hasItems: boolean;
  itemsCount: number;
}

export interface BasicBudgetUnit {
  id: string;
  name: string;
}

export interface BudgetUnit {
  id: string;
  name: string;
  totalBudget: number;
  actualBudget: number;
  fixedCosts: number;
  remaining: number;
  ancestors: string[];
  hasBudgetItems: boolean;
}

export interface BudgetUnitConnectionInput {
  budgetUnitId: string;
  budgetId: string;
  categoryIds?: string[];
  budgetItemTemplateNames?: string[];
}

export interface BudgetUnitsConnection {
  totalCount: number;
  budgetUnits: BudgetUnit[];
}

export interface BudgetUnitsConnectionInput {
  budgetId: string;
  parentBudgetUnitId: string;
  categoryIds: string[];
  budgetItemTemplateNames: string[];
  sortBy?: string;
  isAscending?: boolean;
  page?: number;
  limit?: number;
}

export interface BudgetUnitSearch {
  id: string;
  name: string;
  ancestors: BudgetUnitSearch[];
}

export interface BudgetUnitSearchConnection {
  totalCount: number;
  searchBudgetUnits: BudgetUnitSearch[];
}

export interface BudgetUnitSearchInput {
  budgetId: string;
  query: string;
  limit?: number;
  page?: number;
}

export interface BudgetChart {
  month: string;
  actualCost: number;
  totalRemaining: number;
}

export interface BudgetChartsConnection {
  totalCount: number;
  budgetCharts: BudgetChart[];
}

export interface BudgetChartsConnectionInput {
  budgetId: string;
  budgetUnitId?: string;
  categoryIds?: string[];
  itemNames?: string[];
}
