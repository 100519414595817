import * as R from 'ramda';

import {
  ModalStep,
  ModalStepType,
} from '@atom/components/common/createAssetModal_NEW/CreateAssetModalContext';
import {
  AssetCreateAttributesInput,
  InventoryCategoryTree,
} from '@atom/types/inventory';
import { Schema } from '@atom/types/schema';
import { isNilOrEmpty } from '@atom/utilities/validationUtilities';

export const MODAL_STEPS: { [key: string]: ModalStepType } = {
  [ModalStep.TYPE]: {
    value: ModalStep.TYPE,
    title: 'Inventory Type',
  },
  [ModalStep.FOLDER]: {
    value: ModalStep.FOLDER,
    title: 'Inventory Folder',
  },
  [ModalStep.LOCATION]: {
    value: ModalStep.LOCATION,
    title: 'Location',
  },
  [ModalStep.COST]: {
    value: ModalStep.COST,
    title: 'Cost Settings',
  },
  [ModalStep.ATTRIBUTES]: {
    value: ModalStep.ATTRIBUTES,
    title: 'Attributes',
  },
  [ModalStep.NAME]: {
    value: ModalStep.NAME,
    title: 'Name',
  },
};

export const getModalStepsArray = (schema: Schema) => {
  const steps = [
    ModalStep.TYPE,
    ModalStep.FOLDER,
    // Location step will be added here when built
    ...(schema?.isMaterial ? [ModalStep.COST] : []),
    // If no attributes exist on the given schema, hide attributes step
    ...(!isNilOrEmpty(schema?.attributes) ? [ModalStep.ATTRIBUTES] : []),
    ModalStep.NAME,
  ];

  return steps.map(step => MODAL_STEPS[step]);
};

interface ValidationData {
  STEPS_ARRAY?: ModalStepType[];
  schema?: Schema;
  category?: InventoryCategoryTree;
  activeStep?: ModalStep;
  rate?: number;
  unit?: string;
  isStockBased?: boolean;
  quantityOnHand?: number;
  name?: string;
  errorAttributeIds?: Set<string>;
}

// Evaluates whether the current step should be disabled
// The current step will be disabled if any previous step is also disabled
export const isCurrentStepDisabled = ({
  STEPS_ARRAY,
  schema,
  category,
  activeStep,
  rate,
  unit,
  isStockBased,
  quantityOnHand,
  name,
  errorAttributeIds,
}: ValidationData) => {
  // Map of all disable validation checks per given modal step
  const stepDisabledRules = {
    [ModalStep.TYPE]: isNilOrEmpty(schema),
    [ModalStep.FOLDER]: isNilOrEmpty(category),
    [ModalStep.LOCATION]: false,
    [ModalStep.COST]:
      R.isNil(rate) || !unit || (isStockBased && R.isNil(quantityOnHand)),
    [ModalStep.ATTRIBUTES]: errorAttributeIds.size > 0,
    [ModalStep.NAME]: isNilOrEmpty(name),
  };

  const currentStepIndex = R.findIndex(
    R.propEq('value', activeStep),
    STEPS_ARRAY,
  );

  // Array of all steps up to the current step
  const stepsToValidate = R.dropLast(
    STEPS_ARRAY.length - currentStepIndex - 1,
    STEPS_ARRAY,
  );

  // Creates an array of validations, denoting whether the step at the given
  // index should be disabled or not
  const validatedSteps = stepsToValidate.map((step: ModalStepType) => {
    return stepDisabledRules[step.value];
  });

  // If any previous step (or current) step are disabled, the step is disabled
  return R.any(R.equals(true), validatedSteps);
};

export const getErrorAttributeIds = (
  schema: Schema,
  attributes: AssetCreateAttributesInput,
): string[] => {
  // List of all required attributeIds
  const requiredAttributeIds = R.keys(schema?.attributes).reduce(
    (acc, attributeId) => {
      return schema?.attributes[attributeId].isRequired
        ? [...acc, attributeId]
        : acc;
    },
    [],
  );

  // List of all attributes that currently have values filled out
  const filledOutAttributeIds = R.keys(attributes).reduce(
    (acc, attributeId) => {
      return isNilOrEmpty(attributes[attributeId].value)
        ? acc
        : [...acc, attributeId];
    },
    [],
  );

  // List of required attributes that do not have values filled out
  const errorAttributeIds = R.difference(
    requiredAttributeIds,
    filledOutAttributeIds,
  );

  return errorAttributeIds;
};

// Remove attribute entries with no values
export const cleanAttributes = (
  attributes: AssetCreateAttributesInput,
): AssetCreateAttributesInput => {
  const keysWithNoValue = R.keys(attributes).reduce((acc, key) => {
    return isNilOrEmpty(attributes[key]) ? [...acc, key] : acc;
  }, []);

  return R.omit(keysWithNoValue, attributes);
};
